<script setup>
import AutoComplete from "primevue/autocomplete";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {computed, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";
import axios from "../Axios.js";

const route = useRoute();
const toast = useToast();

const tournamentId = ref(route.params.tid || null);

const users = ref([]);
const filteredUsers = ref([]);
const tournament = ref(null);
const teamName = ref('');
const players = ref([]);
const subPlayer = ref(null);
const isSubmitting = ref(false);

const meta = computed(() => {
  if (tournament.value) {
    return tournament.value.meta;
  }
  return {};
});

const teamSize = computed(() => {
  if (tournament.value) {
    return tournament.value.team_min_size;
  }
  return 0;
});

const search = (event) => {
  filteredUsers.value = users.value.filter((user) => {
    const displayName = user.globalName || user.username;
    return displayName.toLowerCase().includes(event.query.toLowerCase()) &&
           !players.value.some(p => p?.id === user.id) && // Exclude already selected players
           (!subPlayer.value || subPlayer.value.id !== user.id); // Exclude selected sub player
  });
}

const resetForm = () => {
  teamName.value = '';
  players.value = [];
  subPlayer.value = null;
}

const getMembers = async () => {
  const resp = await axios.get(`/members`);
  users.value = resp.data.filter((user) => !user.bot);
}

const getTournament = async () => {
  try {
    const resp = await axios.get(`/tournament?tournamentId=${tournamentId.value}`);
    tournament.value = resp.data;
  } catch (e) {
    console.error(e);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: e.response?.data?.error || 'Tournament not found'
    });
  }
}

const validateTeam = () => {
  if (!teamName.value) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Team name is required'
    });
    return false;
  }

  if (!/^[a-zA-Z0-9]+$/.test(teamName.value)) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Team name can only contain letters and numbers (no spaces)'
    });
    return false;
  }

  if (players.value.length !== teamSize.value) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: `Team must have exactly ${teamSize.value} players (currently has ${players.value.length})`
    });
    return false;
  }

  // Validate all player objects are complete
  const invalidPlayers = players.value.filter(p => !p || !p.id || !p.username);
  if (invalidPlayers.length > 0) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'All player slots must be filled with valid players'
    });
    return false;
  }

  // Check for duplicate players
  const allPlayerIds = players.value.map(p => p.id);
  if (new Set(allPlayerIds).size !== allPlayerIds.length) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Duplicate players detected in the roster'
    });
    return false;
  }

  return true;
}

const submit = async () => {
  if (isSubmitting.value) return;
  if (!validateTeam()) return;

  isSubmitting.value = true;

  try {
    // Format players array according to API expectations
    const formattedPlayers = players.value.map(player => ({
      id: player.id,
      username: player.globalName || player.username
    }));

    // Prepare request payload matching the API route structure
    const payload = {
      players: formattedPlayers,
      teamName: teamName.value,
      tournamentId: tournamentId.value,
      subPlayer: subPlayer.value ? {
        id: subPlayer.value.id,
        username: subPlayer.value.globalName || subPlayer.value.username
      } : undefined
    };

    const response = await axios.post(`/register`, payload);

    if (response.data.success) {
      resetForm();
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: response.data.message || 'Team registered successfully!'
      });
    } else {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: response.data.error || 'Registration failed'
      });
    }
  } catch (e) {
    console.error(e);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: e.response?.data?.error || 'Registration failed'
    });
  } finally {
    isSubmitting.value = false;
  }
}

onMounted(async () => {
  await getMembers();
  await getTournament();
});
</script>

<template>
  <div v-if="tournament" class="flex flex-col md:flex-row gap-8 items-center justify-center w-full">
    <div class="p-4 bg-cyan-900 border border-cyan-600 mb-10">
      <p class="mb-3 text-red-400">
        ONLY Team Captains should register!<br/>
        All players must be in our discord to be added to the team.
      </p>
      <ul class="list-none p-0 text-gray-300">
        <li class="text-xl font-bold text-white">{{ tournament.name }}</li>
        <li>📆: {{ meta.starts }} EST</li>
        <li>🏆: {{ meta.prize }} Prize</li>
        <li>💵: {{ meta.entry }} Entry</li>
        <li>👥: Min {{ meta.min_teams }} Teams</li>
        <li>🌎: {{ meta.regions.toUpperCase() }}</li>
        <li>🏅: {{ meta.stage === 'se' ? 'Single Elimination' : 'Double Elimination' }}</li>
      </ul>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <label for="teamname" class="font-medium text-surface-900 dark:text-surface-0 mb-1 block">
          Team Name
        </label>
        <InputText
            v-model="teamName"
            id="teamname"
            type="text"
            class="w-full"
            @input="$event => teamName = $event.target.value.replace(/[^a-zA-Z0-9]/g, '')"
        />
      </div>
      <div v-for="n in teamSize" class="col-span-12 flex justify-between items-center">
        <label class="font-medium text-surface-900 dark:text-surface-0 mb-1 mr-4">
          <span class="text-red-500 mr-1">*</span> Player {{ n }}
          <span class="text-sm text-gray-400" v-if="n === 1">(Captain)</span>
        </label>
        <AutoComplete
            v-model="players[n - 1]"
            :suggestions="filteredUsers"
            :optionLabel="(user) => user.globalName || user.username"
            @complete="search"
            forceSelection
            class="w-64"
        />
      </div>

      <div class="col-span-12 flex justify-between items-center">
        <label class="font-medium text-surface-900 dark:text-surface-0 mb-1 mr-4">
          Sub Player (Optional)
        </label>
        <AutoComplete
            v-model="subPlayer"
            :suggestions="filteredUsers"
            :optionLabel="(user) => user.globalName || user.username"
            @complete="search"
            forceSelection
            class="w-64"
        />
      </div>

      <div class="col-span-12 flex justify-end">
        <Button
            @click="submit"
            label="Register Team"
            class="w-auto mt-4"
            :disabled="isSubmitting"
            :loading="isSubmitting"
        />
      </div>
    </div>
  </div>
</template>