<script setup>
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import {ref} from 'vue';

const allGamesRules = ref([
  {
    title: 'What are the general rules?',
    description: '• CONTROLLER ONLY\n' +
        '• Snaking has been banned across all games!\n' +
        '• Zero-tolerance policy for cheating/hacking - permanent ban if caught using any software/device to cheat\n' +
        '• ALL PC Players MUST stream with Past Broadcasts turned on\n' +
        '• Failure to provide proof of VODs during any portion of a map is a map forfeit!\n' +
        '• Teams must verify opponent\'s audio (game audio and comms) before completing match - completed matches won\'t be replayed for audio issues\n' +
        '• All disputes handled by EpiQ Gaming Management - be ready with livestream/screenshot proof\n' +
        '• Leaving a match before it ends will result in Match Forfeit!'
  },
  {
    title: 'What are the 2v2 competition rules?',
    description: '• 1 AR and 1 Sub\n' +
        '• No Smokes\n' +
        '• You are permitted to pick up guns from fallen players'
  },
  {
    title: 'PC Player Requirements',
    description: '• ALL PC Players MUST stream with Past Broadcasts turned on\n' +
        '• Failure to provide proof of VODs during any portion of a map is a map forfeit!'
  },
  {
    title: 'Streaming Requirements',
    description: '• ALL PC Players MUST stream with Past Broadcasts turned on\n' +
        '• Failure to provide proof of VODs during any portion of a map is a map forfeit!'
  }
]);

const bo6Rules = ref([
  {
    title: 'What are the Game Settings (ALL MODES)?',
    description:  'You must use the CDL game mode and unlock the rules to make the required change.\n\n' +
        'Game:\n' +
        '• Allow call out pings - OFF\n\n' +
        'Player:\n' +
        '• No Changes\n\n' +
        'Team:\n' +
        '• Killcams - ON\n' +
        '• Friendly fire - ON\n\n' +
        'Gameplay:\n' +
        '• Dynamic map elements - OFF'
  },
  {
    title: 'What Primary Weapons are BANNED?',
    description: '• As Val\n' +
        '• Goblin MK2\n' +
        '• Model L\n' +
        '• XM4\n' +
        '• ALL Shotguns\n' +
        '• ALL LMGs\n' +
        '• ALL Marksman Rifles\n' +
        '• ALL Sniper Rifles'
  },
  {
    title: 'What Secondary Weapons are BANNED?',
    description: '• ALL Launchers\n' +
        '• Grekhova Machine Pistol'
  },
  {
    title: 'What Attachments are BANNED?',
    description: '• ALL Optics with magnification\n' +
        '• ALL Launcher Underbarrels\n' +
        '• ALL Lasers\n' +
        '• ALL Silencers\n' +
        '• CHF Barrel\n' +
        '• Rapid Fire\n' +
        '• FMJ\n' +
        '• Integrated Suppressor Barrel\n' +
        '• Akimbo'
  },
  {
    title: 'What Melee Weapons are ALLOWED?',
    description: '• Only the Knife is allowed'
  },
  {
    title: 'What Scorestreaks are ALLOWED?',
    description: 'Only the following scorestreaks are allowed:\n' +
        '• RC-XD\n' +
        '• LDBR\n' +
        '• Hellstorm'
  },
  {
    title: 'What Lethals are ALLOWED?',
    description: 'Only the following lethals are allowed:\n' +
        '• Frag\n' +
        '• Semtex'
  },
  {
    title: 'What Tacticals are ALLOWED?',
    description: 'Only the following tacticals are allowed:\n' +
        '• Concussion'
  },
  {
    title: 'What Field Upgrades are ALLOWED?',
    description: 'Only the following field upgrade is allowed:\n' +
        '• Trophy System'
  },
  {
    title: 'What Perks are ALLOWED?',
    description: 'Perk 1 (Allowed):\n' +
        '• Gung-Ho\n' +
        '• Dexterity\n' +
        '• Ninja\n' +
        '• Flak Jacket\n' +
        '• Tac Mask\n\n' +
        'Perk 2 (Allowed):\n' +
        '• Fast Hands\n\n' +
        'Perk 3 (Allowed):\n' +
        '• Double Time\n' +
        '• Cold Blooded\n\n' +
        'NOTE: ALL Specialty Perks are BANNED'
  },
  {
    title: 'What Wildcards are ALLOWED?',
    description: 'Only the following wildcards are allowed:\n' +
        '• Gunfighter\n' +
        '• Perk Greed'
  },
  {
    title: 'Are there any Operator restrictions?',
    description: '• Currently there are no operators banned'
  },
  {
    title: 'What happens if restricted items are used?',
    description: '• Usage of a restricted item in a match will result in a forfeit of the most recent round played\n' +
        '• If you see opponents using a restricted item, you must leave the game the following round and request an admin in your match chat for further assistance'
  }
]);

const mw3Rules = ref([
  {
    title: 'What are the S&D Settings?',
    description: 'Game Settings:\n' +
        '• Round Time Limit: 1:30\n' +
        '• Round win Limit: 6 Rounds\n' +
        '• Round switch: Every Round\n' +
        '• Skip Infil: On\n' +
        '• Practice Round: OFF\n' +
        '• Input swap allowed: OFF\n\n' +
        'Advanced Settings:\n' +
        '• Defuse time: 7.5\n' +
        '• Silent Plant: ON\n' +
        '• Reset Plant / Defuse: ON\n\n' +
        'Team Settings:\n' +
        '• 3rd Person Spectating: OFF\n' +
        '• Friendly fire: ON\n\n' +
        'Gameplay Settings:\n' +
        '• Equipment Delay: 5 seconds\n' +
        '• Battle chatter: OFF\n' +
        '• Dynamic map elements: OFF'
  },
  {
    title: 'What weapons & attachments are restricted?',
    description: 'Primary Weapons Banned:\n' +
        '• ALL MWII WEAPONS\n' +
        '• ALL Snipers have been GA\'d\n' +
        '• ALL VARIANTS OR BLUE PRINTS THAT SHOOT TRACER ROUNDS\n' +
        '• DG-58\n' +
        '• FR 5.56\n' +
        '• Holger 556\n' +
        '• MTZ-556\n' +
        '• All Battle Rifles\n' +
        '• All Shotguns\n' +
        '• All LMGs\n' +
        '• All Marksman Rifles\n' +
        '• Riot Shield\n\n' +
        'Secondary Weapons Banned:\n' +
        '• ALL MWII WEAPONS\n' +
        '• Launchers\n' +
        '• TYR\n' +
        '• All Melee Weapons\n\n' +
        'Attachment Restrictions:\n' +
        '• AR\'s MUST use a red dot sight\n' +
        '• All MWII weapon attachments\n' +
        '• High Grain ammunition\n' +
        '• All pistol attachments (except movement)\n' +
        '• All Silencers/Suppressors\n' +
        '• Attachments hiding red dot on mini map\n' +
        '• Akimbo\n' +
        '• All Ammo Types\n' +
        '• All Thermal Sights\n' +
        '• All Conversion Kits\n' +
        '• All Pistol Triggers\n' +
        '• All melee damage increasing muzzles'
  },
  {
    title: 'What equipment & streaks are allowed?',
    description: 'Kill Streaks Allowed:\n' +
        '• Precision Airstrike\n' +
        '• Cruise Missile\n\n' +
        'Lethals Allowed:\n' +
        '• Frag\n' +
        '• Semtex\n\n' +
        'Tacticals Allowed:\n' +
        '• Stun\n' +
        '• Flash\n' +
        '• Smoke (Only one allowed per team in SnD)\n\n' +
        'Field Upgrades:\n' +
        '• Trophy System (2 max per team)\n' +
        '• Dead Silence\n\n' +
        'Banned Field Upgrades:\n' +
        '• Portable Radar'
  },
  {
    title: 'What perks & gear restrictions exist?',
    description: 'Banned Vests:\n' +
        '• Demolition\n' +
        '• Gunner\n' +
        '• Overkill\n' +
        '• CCT Comms\n\n' +
        'Gear Allowed:\n' +
        '• EOD\n' +
        '• Tac Mask\n' +
        '• Mag Holster\n\n' +
        'Gloves & Boots:\n' +
        '• All allowed'
  },
  {
    title: 'What operator restrictions exist?',
    description: 'Banned Operators:\n' +
        '• Gaia\n' +
        '• All Blackcell operators\n' +
        '• The Calisto Undead Skin'
  }
]);

const mw2Rules = ref([
  {
    title: 'What ruleset is used?',
    description: '• Refer to the official CDL Restrictions at callofdutyleague.com/competitive-settings'
  },
  {
    title: 'What additional restrictions exist?',
    description: '• Taq must have the Tundra Pro Barrel!\n' +
        '• MP7 banned\n' +
        '• Stair Glitching banned\n' +
        '• The cliff glitch spot next to top Dome banned (Ask if unsure)'
  }
]);

const vgRules = ref([
  {
    title: 'What weapons & attachments are restricted?',
    description: 'Banned Weapons:\n' +
        '• Shotguns\n' +
        '• LMGS\n' +
        '• Riot Shield\n' +
        '• Launchers\n\n' +
        'Banned Attachments:\n' +
        '• Silencers\n' +
        '• Subsonic and Incendiary ammunition types'
  },
  {
    title: 'What perks & equipment are restricted?',
    description: 'Banned Perks:\n' +
        '• Perk 1: Ghost, Dauntless\n' +
        '• Perk 2: Tracker, High Alert, Forward Intel, Piercing Vision\n' +
        '• Perk 3: Demolition, Overkill, Tactician\n\n' +
        'Banned Equipment:\n' +
        '• Lethal: Throwing Bomb, Thermite, Demolition Charge, Molotov\n' +
        '• Tactical: Stim, Gas Grenade, S-Mine, Decoy'
  },
  {
    title: 'What field upgrades & streaks are allowed?',
    description: 'Allowed Field Upgrades:\n' +
        '• Supply Box\n' +
        '• Dead Silence\n' +
        '• All others restricted\n\n' +
        'Killstreaks:\n' +
        '• Only Glide Bomb allowed\n' +
        '• All others restricted'
  }
]);

const cwRules = ref([
  {
    title: 'What weapons are banned?',
    description: 'Primary Weapons:\n' +
        '• All Light Machine Guns\n' +
        '• SA87\n' +
        '• M91\n' +
        '• PKM\n' +
        '• MG34\n' +
        '• L86A2\n' +
        '• Holger-26\n' +
        '• Bruen Mk9\n\n' +
        'Secondary Weapons:\n' +
        '• All Shotguns (Hauer 77, Gallo SA12)\n' +
        '• All Launchers (Cigma 2, RPG-7)\n' +
        '• All Special (M79)'
  },
  {
    title: 'What attachments & equipment are banned?',
    description: 'Banned Attachments:\n' +
        '• SWAT 5mw Laser Sight\n' +
        '• Ember Sighting Point\n' +
        '• Dual Wield\n\n' +
        'Banned Tactical Equipment:\n' +
        '• Stimshot\n' +
        '• Decoy\n\n' +
        'Banned Lethal Equipment:\n' +
        '• C4\n' +
        '• Tomahawk\n\n' +
        'Banned Field Upgrades:\n' +
        '• Proximity Mine\n' +
        '• Field Mic\n' +
        '• Gas Mine'
  },
  {
    title: 'What perks & streaks are banned?',
    description: 'Banned Perks:\n' +
        '• Perk 1: Paranoia, Forward Intel\n' +
        '• Perk 2: Tracker\n\n' +
        'Banned Wildcards:\n' +
        '• Danger Close\n\n' +
        'Banned Scorestreaks:\n' +
        '• Combat Bow\n' +
        '• Spy Plane\n' +
        '• Counter Spy Plane\n' +
        '• Care Package\n' +
        '• Sentry Turret\n' +
        '• Air Patrol'
  },
  {
    title: 'What are the Gentlemen\'s Agreements (GA\'s)?',
    description: 'Weapons GA\'s:\n' +
        '• ARs: AK-47, XM4\n' +
        '• SMGs: KSP 45\n' +
        '• Pistols: 1911, Magnum\n' +
        '• All Sniper Rifles restricted\n\n' +
        'Attachment GA\'s:\n' +
        '• Thermal sights\n' +
        '• No scopes on ARs\n' +
        '• Task Force Barrel\n' +
        '• All body attachments\n' +
        '• KGB/Socom Eliminator\n' +
        '• All AK-74u Magazine attachments\n\n' +
        'Perk & Wildcard GA\'s:\n' +
        '• Perk Greed\n' +
        '• Law Breaker (only allowed without overkill)\n' +
        '• Gearhead, Gung-Ho, Assassin\n\n' +
        'Other GA\'s:\n' +
        '• Auto Sprint must be disabled\n' +
        '• Snaking banned (3+ repetitions)\n' +
        '• Map glitches/exploits prohibited\n' +
        '• Respectful communication required'
  }
]);

const games = ref([
  {
    title: 'All Games',
    rules: allGamesRules
  },
  {
    title: 'Call Of Duty: Black Ops 6',
    rules: bo6Rules
  },
  {
    title: 'Call Of Duty: Modern Warfare III',
    rules: mw3Rules
  },
  {
    title: 'Call Of Duty: Modern Warfare II',
    rules: mw2Rules
  },
  {
    title: 'Call Of Duty: Vanguard',
    rules: vgRules
  },
  {
    title: 'Call Of Duty: Black Ops Cold War',
    rules: cwRules
  }
]);
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 md:px-12">
    <h4 class="text-6xl font-bold text-surface-900 dark:text-surface-0 text-center">Game Rules</h4>
    <p class="leading-6 text-surface-700 dark:text-surface-300 mt-2 text-center text-xl">Complete ruleset for all
      supported Call of Duty titles. Select a game below to view its specific rules.</p>
    <div class="mt-10 p-6 rounded-border text-lg">
      <Accordion
          value="0"
          :dt="{
                    contentBackground: 'transparent'
                }"
      >
        <AccordionPanel v-for="(game, gameIndex) in games" :key="game.title" :value="String(gameIndex)"
                        :class="gameIndex === games.length - 1 ? '!border-0' : ''">
          <AccordionHeader class="!bg-transparent">{{ game.title }}</AccordionHeader>
          <AccordionContent class="bg-gray-800 p-4">
            <Accordion
                :dt="{
                                contentBackground: 'transparent'
                            }"
            >
              <AccordionPanel v-for="(rule, ruleIndex) in game.rules" :key="rule.title" :value="String(ruleIndex)"
                              :class="ruleIndex === game.rules.length - 1 ? '!border-0' : ''">
                <AccordionHeader class="!bg-transparent">{{ rule.title }}</AccordionHeader>
                <AccordionContent class="bg-gray-900 p-4">
                  <p class="whitespace-pre-line">{{ rule.description }}</p>
                </AccordionContent>
              </AccordionPanel>
            </Accordion>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
  </div>
</template>