<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";
import axios from "../Axios.js";

const route = useRoute();
const toast = useToast();

const tournamentId = ref(route.params.tid || null);

const fetchDataAndRender = async () => {
  if (!tournamentId) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Tournament not found'});
    return;
  }

  try {
    const origin = window.location.origin;
    const resp = await axios.get(`${origin}/api/bracket?tournamentId=${tournamentId.value}`);

    if (!resp.data) {
      toast.add({severity: 'error', summary: 'Error', detail: 'Network error'});
    }
    const data = await resp.data;

    if (!data.stage.length) {
      toast.add({severity: 'info', summary: 'Info', detail: 'There is no stage for this tournament. Most likely because the tournament has not started!'});
    }

    await window.bracketsViewer.render({
      stages: data.stage,
      matches: data.match,
      matchGames: data.match_game,
      participants: data.participant,
    });
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error', detail: error.message});
  }
}

onMounted(async () => {
  await fetchDataAndRender();
});

</script>

<template>
  <div class="brackets-viewer"></div>
</template>

<style scoped>
.brackets-viewer {
  /* Colors */
  --primary-background: transparent;
  --secondary-background: #414141;
  --match-background: #1a1a1a;
  --font-color: #d9d9d9;
  --win-color: #50b649;
  --loss-color: #e61a1a;
  --label-color: grey;
  --hint-color: #a7a7a7;
  --connector-color: #9e9e9e;
  --border-color: #d9d9d9;
  --border-hover-color: #b6b5b5;

  /* Sizes */
  --text-size: 12px;
  --round-margin: 40px;
  --match-width: 150px;
  --match-horizontal-padding: 8px;
  --match-vertical-padding: 6px;
  --connector-border-width: 2px;
  --match-border-width: 1px;
  --match-border-radius: 0.3em;
}
</style>