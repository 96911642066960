<script setup>
import Button from "primevue/button";
import Toast from "primevue/toast";
import {ref} from 'vue';
import {useRouter} from "vue-router";

const router = useRouter();

const navs = ref([
  {
    icon: 'pi pi-home',
    label: 'Home',
    to: '/home'
  },
  {
    icon: 'pi pi-trophy',
    label: 'Tournaments',
    to: '/tournaments'
  },
  {
    icon: 'pi pi-exclamation-circle',
    label: 'Item Restrictions',
    to: '/faqs/item-restrictions'
  },
  {
    icon: 'pi pi-file-edit',
    label: 'Rules',
    to: '/faqs/tournament'
  },
  {
    icon: 'pi pi-discord',
    label: 'Join Discord',
    to: 'href=https://discord.gg/pqZtBtfE6m'
  }
]);

const goTo = async (path) => {
  if (path.startsWith('href=')) {
    window.open(path.substring(5), '_blank');
    return;
  }

  await router.push(path);
};
</script>

<template>
  <div class="mx-1 sm:mx-10 lg:mx-28 mb-20">
    <div class="bg-surface-0 dark:bg-surface-900">
      <div class="relative flex items-center justify-between gap-4 2xl:gap-6">
        <div class="flex items-center gap-3 px-8 py-4">
          <img src="/images/logo.png" alt="logo" width="150"/>
        </div>
        <a
            v-styleclass="{
                    selector: '@next',
                    enterFromClass: 'hidden',
                    leaveToClass: 'hidden',
                    hideOnOutsideClick: true
                }"
            class="mr-8 cursor-pointer block lg:hidden text-surface-900 dark:text-surface-0"
        >
          <i class="pi pi-bars text-xl"/>
        </a>
        <div
            class="flex-1 lg:shadow-none shadow-md rounded-b-xl lg:rounded-none p-4 top-full right-0 w-full bg-surface-0 dark:bg-surface-900 absolute lg:static lg:flex hidden lg:flex-row flex-col items-center justify-between transition-all"
        >
          <div class="flex items-center gap-2 lg:flex-row flex-col">
            <template v-for="(item, index) of navs" :key="index">
              <Button :label="item.label" :icon="item.icon" text severity="secondary"
                      class="lg:w-auto w-full !rounded-lg !leading-snug lg:!justify-center !justify-start"
                      @click="goTo(item.to)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <Toast/>
  </div>
</template>