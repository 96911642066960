import "primeicons/primeicons.css";
import './assets/main.css';

import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import Aura from './presets/Aura.js';
import App from './App.vue';
import router from './router/index.js';
import ToastService from "primevue/toastservice";
import StyleClass from "primevue/styleclass";

const app = createApp(App);

app.use(router);

app.use(ToastService);
app.directive('styleclass', StyleClass);

app.use(PrimeVue, {
    theme: {
    preset: Aura
  }
});

app.mount('#app');
