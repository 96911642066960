import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/Home.vue';
import RegisterTeam from "../views/RegisterTeam.vue";
import TournamentFaqs from "../views/TournamentFaqs.vue";
import ItemRestrictionFaqs from "../views/ItemRestrictionFaqs.vue";
import Bracket from "../views/Bracket.vue";
import PaymentSuccess from "../views/PaymentSuccess.vue";
import PaymentCancel from "../views/PaymentCancel.vue";
import Tournaments from "../views/Tournaments.vue";
import EpiqTv from "../views/EpiqTv.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {path: '', redirect: '/home'},
            {path: 'home', component: Home},
            {path: 'payment/success', component: PaymentSuccess},
            {path: 'payment/cancel', component: PaymentCancel},
            {path: 'faqs/tournament', component: TournamentFaqs},
            {path: 'faqs/item-restrictions', component: ItemRestrictionFaqs},
            {path: 'register/team/:tid', component: RegisterTeam},
            {path: 'bracket/:tid', component: Bracket},
            {path: 'tournaments', component: Tournaments},
        ]
    },
    // EpiqTV route outside of default layout
    {path: '/epiqtv/:id', component: EpiqTv}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {left: 0, top: 0};
    }
});

export default router;