<script setup>
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import { ref } from 'vue';

const tournamentRules = ref([
    {
        title: 'Why do I need to know all the rules?',
        description: '• "I DID NOT KNOW" is an unacceptable answer to any violations of the rules\n' +
            '• You are responsible for reading all rules and asking questions if something is not clear'
    },
    {
        title: 'What are the general rules?',
        description: '• CONTROLLER ONLY\n' +
            '• Snaking has been banned across all games!\n' +
            '• Zero-tolerance policy for cheating/hacking - permanent ban if caught using any software/device to cheat\n' +
            '• ALL PC Players MUST stream with Past Broadcasts turned on\n' +
            '• Failure to provide proof of VODs during any portion of a map is a map forfeit!\n' +
            '• Teams must verify opponent\'s audio (game audio and comms) before completing match - completed matches won\'t be replayed for audio issues\n' +
            '• All disputes handled by EpiQ Gaming Management - be ready with livestream/screenshot proof\n' +
            '• Leaving a match before it ends will result in Match Forfeit!'
    },
    {
        title: 'How do payouts work?',
        description: '• Must have CashApp, Venmo or PayPal to receive earnings\n' +
            '• Payouts will be deposited as \'Credits\' linked to your discord account immediately\n' +
            '• Payouts will go to the Team Captain, who is responsible for distributing earnings to their team\n' +
            '• Payouts may be held if disputes regarding tournament winners are raised - EpiQ Gaming staff will investigate to determine the true winner before releasing payouts\n' +
            '• Credits can be used to enter future tournaments OR you can request to \'Cashout\'\n' +
            '• You can Cashout a specific amount of credits OR a full cashout\n' +
            '• Cashouts are processed within 24-48 hours of tournament completion'
    },
    {
        title: 'What are the entry fee requirements?',
        description: '• Team Captains are responsible for entry fees for their team\n' +
            '• Credits for entry fees can be purchased using EpiQBot on Discord\n' +
            '• While some tournaments are FREE ENTRY, some require entry fees\n' +
            '• Teams that do not participate will forfeit all entry fees\n' +
            '• If your team cannot attend, you MUST leave the tournament before it starts to receive a credit refund\n' +
            '• EpiQ Gaming does not profit from entry fees - 100% goes to tournament winner(s)'
    },
    {
        title: 'What are the roster requirements?',
        description: '• ALL team members MUST be in the Discord server before you can register them as a player\n' +
            '• Team Captains are responsible for ensuring all team members are in the Discord server\n' +
            '• Team Captains are responsible for ensuring all team members are connected to a Voice Channel before the tournament starts\n' +
            '• When registering you can specify your substitute player. This player is the only substitute that is eligible to play\n' +
            '• If you don\'t have a substitute player available you are welcome to acquire one from the \'Sub Pool\'\n' +
            '• The Sub Pool is a list of players who are available to play as a substitute for teams who need one\n' +
            '• If you use a substitute player, they will be your designated sub for the entire tournament (original player can still play when available)'
    },
    {
        title: 'What are the region restrictions?',
        description: '• Most EpiQ Gaming events will be NA ONLY, However there are times we will host events for other regions\n' +
            '• EU, CA, and LATAM players are not permitted in NA tournaments'
    },
    {
        title: 'What are the 2v2 competition rules?',
        description: '• 1 AR and 1 Sub\n' +
            '• No Smokes\n' +
            '• You are permitted to pick up guns from fallen players'
    },
    {
        title: 'What happens if teams don\'t show up?',
        description: '• Teams have 10 minutes from scheduled match time to "Ready-Up"\n' +
            '• After both teams ready, 5 additional minutes for lobby setup\n' +
            '• Over 5 minutes between Maps is considered torching - could result in Map Forfeit\n' +
            '• Teams not ready within 10 minutes risk being forfeited and ready team will advance\n' +
            '• If no teams ready before 10-minute, staff reserve the right to advance a random team\n' +
            '• If a random team advances due to a no-show, that team will not be advanced for a no-show a second time\n' +
            '• If both teams are "Ready" and a team goes AFK, use "Request Help" button to notify staff about time torching'
    },
    {
        title: 'How are disconnects handled?',
        description: '• Hardpoint: If both teams below 100, map will be replayed\n' +
            '• SnD & Control: Mid-round disconnects will be played out\n' +
            '• SnD & Control: Hosts must end match and restart from where it left off'
    },
    {
        title: 'Are coaches and/or spectators allowed?',
        description: '• Coaches ARE NOT permitted in game or voice channels\n' +
            '• While other "leagues" may allow this, we simply do not!\n' +
            '• Spectators are allowed in the Discord server, but not in the game or voice channels\n' +
            '• Spectators are not allowed to provide any information to players during the match\n' +
            '• If you start a match and there is a non-participating member in your VC, you will FF that round regardless if it was played out or not.'
    },
    {
        title: 'What glitches are banned?',
        description: '• Planting/defusing through walls or structures\n' +
            '• Going to out of bounds "glitch" spots\n' +
            '• Laying down or crouching into objects which make your player model hidden or invincible (Exception: tall grass or bushes is allowed)\n' +
            '• Stair & Curb Glitching'
    },
    {
        title: 'What happens if restricted items are used?',
        description: '• Usage of a restricted item in a match will result in a forfeit of the most recent round played\n' +
            '• If you see opponents using a restricted item, you must leave the game the following round and request an admin in your match chat for further assistance'
    }
]);
</script>

<template>
    <div class="bg-surface-0 dark:bg-surface-950 px-4 md:px-12">
        <h4 class="text-3xl md:text-6xl font-bold text-surface-900 dark:text-surface-0 text-center mb-4">Tournament FAQ's</h4>
        <p class="leading-6 text-surface-700 dark:text-surface-300 mt-2 text-center text-base md:text-xl px-2 md:px-0">
            Everything you need to know about participating in EpiQ Gaming tournaments, from payouts to roster requirements.
        </p>
        <div class="mt-6 md:mt-10 p-2 md:p-6 rounded-border text-base md:text-lg">
            <Accordion
                value="0"
                :dt="{
                    contentBackground: 'transparent'
                }"
            >
                <AccordionPanel
                    v-for="(rule, index) in tournamentRules"
                    :key="rule.title"
                    :value="String(index)"
                    :class="[
                        index === tournamentRules.length - 1 ? '!border-0' : '',
                        'transition-all duration-200'
                    ]"
                >
                    <AccordionHeader class="!bg-transparent p-3 md:p-4">
                        <span class="text-sm md:text-base">{{ rule.title }}</span>
                    </AccordionHeader>
                    <AccordionContent class="bg-gray-800 p-3 md:p-4">
                        <p class="whitespace-pre-line text-sm md:text-base">{{ rule.description }}</p>
                    </AccordionContent>
                </AccordionPanel>
            </Accordion>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 640px) {
    :deep(.p-accordion-header-link) {
        padding: 0.75rem !important;
    }

    :deep(.p-accordion-content) {
        padding: 0.75rem !important;
    }
}
</style>