<script setup>

</script>
<template>
    <div class="bg-surface-0 dark:bg-surface-950 px-4 md:px-12">
        <h4 class="text-3xl md:text-6xl font-bold text-surface-900 dark:text-surface-0 text-center mb-4">Payment Cancelled</h4>
        <p class="leading-6 text-surface-700 dark:text-surface-300 mt-2 text-center text-base md:text-xl px-2 md:px-0">
            Your payment has been cancelled. If you have any questions, please contact support.
        </p>
    </div>
</template>