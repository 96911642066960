<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import axios from '../Axios.js';

const route = useRoute();
const tournament = ref(null);
const matches = ref([]);
const currentMatch = ref(null);
const updateInterval = ref(null);
const notFound = ref(false);

// Animation states
const slideDirection = ref('left');
const showMatchInfo = ref(true);
const bracketVisible = ref(true);

// Process matches into rounds for the bracket
const rounds = computed(() => {
  if (!matches.value) return [];

  // Group matches by depth (rounds)
  const groupedMatches = matches.value.reduce((acc, match) => {
    const depth = match.depth;
    if (!acc[depth]) acc[depth] = [];
    acc[depth].push(match);
    return acc;
  }, {});

  // Sort matches within each round by number
  return Object.entries(groupedMatches)
    .sort(([a], [b]) => b - a) // Sort rounds by depth (descending)
    .map(([depth, matches]) => ({
      name: depth === "1" ? "Finals" :
            depth === "2" ? "Semi Finals" :
            depth === "3" ? "Quarter Finals" :
            `Round ${depth}`,
      matches: matches.sort((a, b) => a.number - b.number)
    }));
});

// Get current matches (in progress)
const currentMatches = computed(() =>
  matches.value?.filter(m => m.status === 'in_progress') || []
);

// Get upcoming matches (pending with both participants)
const upcomingMatches = computed(() =>
  matches.value?.filter(m =>
    m.status === 'pending' &&
    m.opponents[0]?.participant &&
    m.opponents[1]?.participant
  ) || []
);

// Get completed matches
const completedMatches = computed(() =>
  matches.value?.filter(m => m.status === 'completed') || []
);

const fetchTournamentData = async () => {
  try {
    if (notFound.value) return;

    const tournamentId = route.params.id;
    const resp = await axios.get(`/epiqtv?tournamentId=${tournamentId}`);

    tournament.value = resp.data.tournament;
    matches.value = resp.data.matches;

    // Find current match
    currentMatch.value = matches.value.find(m => m.status === 'in_progress');
  } catch (error) {
    if (error.response.status === 404) {
      notFound.value = true;
      console.log('Tournament not found:', error.response.data);
    } else {
      console.error('Error fetching tournament data:', error);
    }
  }
};

const getTeamName = (opponent) => {
  if (!opponent) return 'TBD';
  if (opponent.participant) return opponent.participant.name;
  if (opponent.source_type === 'winner') return 'Winner of Match';
  return 'TBD';
};

const getMatchScore = (opponent) => {
  return opponent?.score || '0';
};

const toggleBracketView = () => {
  showMatchInfo.value = false;
  setTimeout(() => {
    bracketVisible.value = !bracketVisible.value;
    showMatchInfo.value = true;
    slideDirection.value = bracketVisible.value ? 'left' : 'right';
  }, 500);
};

onMounted(() => {
  fetchTournamentData();
  setInterval(fetchTournamentData, 10000);
  setInterval(toggleBracketView, 30000);
});

onUnmounted(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value);
  }
});
</script>

<template>
  <div class="broadcast-container bg-gradient-to-br from-gray-900 to-gray-800 min-h-screen text-white overflow-hidden">
    <header v-if="notFound" class="p-6 bg-black/30 border-b border-white/10">
      <div class="container mx-auto">
        <h1 class="text-4xl font-bold text-center">
          Tournament Not Found
        </h1>
      </div>
    </header>
    <!-- Tournament Header -->
    <header class="p-6 bg-black/30 border-b border-white/10">
      <div class="container mx-auto">
        <div class="flex items-center justify-center gap-4">
          <img src="/images/logo.png" alt="Logo" class="h-12 w-12"/>
          <h1 class="text-4xl font-bold text-center animate-pulse">
            {{ tournament?.name }}
          </h1>
        </div>
        <div class="text-center mt-2 text-gray-300">
          Prize Pool: {{ tournament?.meta?.prize }} | Format: {{ tournament?.meta?.team_size }}
          {{ tournament?.meta?.mode?.toUpperCase() }}
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="container mx-auto p-6">
      <transition
          :enter-active-class="`animate__animated animate__slide${slideDirection === 'left' ? 'InLeft' : 'InRight'}`"
          :leave-active-class="`animate__animated animate__slide${slideDirection === 'left' ? 'OutRight' : 'OutLeft'}`"
      >
        <!-- Tournament Bracket View -->
        <div v-if="bracketVisible" class="bracket-view">
          <div class="grid gap-8" :style="{ 'grid-template-columns': `repeat(${rounds.length}, 1fr)` }">
            <div v-for="round in rounds" :key="round.name" class="round">
              <h3 class="text-xl font-semibold text-center mb-6 text-blue-400">
                {{ round.name }}
              </h3>
              <div class="flex flex-col gap-8">
                <div
                    v-for="match in round.matches"
                    :key="match.id"
                    class="match-card bg-gray-800/50 p-4 rounded-lg border border-white/10 transform transition-all"
                    :class="{
                    'border-green-500 shadow-lg shadow-green-500/20': match.status === 'in_progress',
                    'border-blue-500 shadow-lg shadow-blue-500/20': match.status === 'pending',
                    'border-gray-500 shadow-lg shadow-gray-500/20': match.status === 'completed'
                  }"
                >
                  <div class="team p-2" :class="{'bg-green-500/20': match.opponents[0]?.result === 'win'}">
                    <div class="flex justify-between items-center">
                      <span class="font-semibold">{{ getTeamName(match.opponents[0]) }}</span>
                      <span class="font-bold">{{ getMatchScore(match.opponents[0]) }}</span>
                    </div>
                  </div>
                  <div class="team p-2 mt-2" :class="{'bg-green-500/20': match.opponents[1]?.result === 'win'}">
                    <div class="flex justify-between items-center">
                      <span class="font-semibold">{{ getTeamName(match.opponents[1]) }}</span>
                      <span class="font-bold">{{ getMatchScore(match.opponents[1]) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Match Information View -->
        <div v-else class="match-info-view">
          <!-- Current Matches -->
          <div v-if="currentMatches.length" class="current-matches mb-8">
            <h2 class="text-2xl font-bold text-center mb-6 text-green-400">Live Matches</h2>
            <div class="grid gap-6">
              <div
                  v-for="match in currentMatches"
                  :key="match.id"
                  class="current-match bg-gray-800/50 p-6 rounded-lg border border-green-500"
              >
                <div class="flex justify-between items-center">
                  <div class="team-info text-center w-1/3">
                    <h3 class="text-xl font-bold">{{ getTeamName(match.opponents[0]) }}</h3>
                    <div class="score text-4xl font-bold text-green-500">
                      {{ getMatchScore(match.opponents[0]) }}
                    </div>
                  </div>
                  <div class="text-center w-1/3">
                    <div class="text-xl font-bold">VS</div>
                    <div class="text-sm text-gray-400 mt-2">
                      Best of {{ tournament?.meta?.best_of }}
                    </div>
                  </div>
                  <div class="team-info text-center w-1/3">
                    <h3 class="text-xl font-bold">{{ getTeamName(match.opponents[1]) }}</h3>
                    <div class="score text-4xl font-bold text-green-500">
                      {{ getMatchScore(match.opponents[1]) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Upcoming Matches -->
          <div v-if="upcomingMatches.length" class="upcoming-matches mb-8">
            <h2 class="text-2xl font-bold mb-4 text-blue-400">Upcoming Matches</h2>
            <div class="grid grid-cols-2 gap-4">
              <div
                  v-for="match in upcomingMatches.slice(0, 4)"
                  :key="match.id"
                  class="match-card bg-gray-800/50 p-4 rounded-lg border border-blue-500"
              >
                <div class="flex justify-between items-center">
                  <span>{{ getTeamName(match.opponents[0]) }}</span>
                  <span class="text-gray-400">vs</span>
                  <span>{{ getTeamName(match.opponents[1]) }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Recent Results -->
          <div v-if="completedMatches.length" class="recent-results">
            <h2 class="text-2xl font-bold mb-4 text-gray-400">Recent Results</h2>
            <div class="grid grid-cols-2 gap-4">
              <div
                  v-for="match in completedMatches.slice(-4)"
                  :key="match.id"
                  class="match-card bg-gray-800/50 p-4 rounded-lg border border-gray-500"
              >
                <div class="flex justify-between items-center">
                  <span :class="{'text-green-500': match.opponents[0]?.result === 'win'}">
                    {{ getTeamName(match.opponents[0]) }}
                  </span>
                  <span class="font-bold">
                    {{ getMatchScore(match.opponents[0]) }} - {{ getMatchScore(match.opponents[1]) }}
                  </span>
                  <span :class="{'text-green-500': match.opponents[1]?.result === 'win'}">
                    {{ getTeamName(match.opponents[1]) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </main>
  </div>
</template>

<style scoped>
@import 'animate.css';

.broadcast-container {
  font-family: 'Inter', sans-serif;
}

.match-card {
  transition: all 0.3s ease;
}

.team-info {
  transition: transform 0.3s ease;
}

.animate__animated {
  animation-duration: 0.5s;
}

/* Bracket connecting lines */
.round:not(:last-child) .match-card::after {
  content: '';
  position: absolute;
  right: -2rem;
  top: 50%;
  width: 2rem;
  height: 2px;
  background: white;
  opacity: 0.2;
}

/* Smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Add glowing effects */
@keyframes glow {
  0% {
    box-shadow: 0 0 20px rgba(34, 197, 94, 0.2);
  }
  50% {
    box-shadow: 0 0 30px rgba(34, 197, 94, 0.4);
  }
  100% {
    box-shadow: 0 0 20px rgba(34, 197, 94, 0.2);
  }
}

.border-green-500 {
  animation: glow 2s infinite;
}
</style>