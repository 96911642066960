<script setup>
import {onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import axios from '../Axios.js';

const router = useRouter();
const tournaments = ref([]);
const selectedTournament = ref(null);
const showDetailsModal = ref(false);

const loadTournaments = async () => {
  try {
    let resp = await axios.get('/tournaments');
    const ids = resp.data.map(t => t.id);

    for (let id of ids) {
      resp = await axios.get(`/tournament?tournamentId=${id}`);
      const tournament = resp.data;
      tournament.game = tournament.name.substring(0, tournament.name.indexOf(' ')).toLowerCase();
      tournaments.value.push(tournament);
    }

    console.log('Tournaments:', tournaments.value);
  } catch (error) {
    console.error('Error loading tournaments:', error);
  }
};

const showDetails = (tournament) => {
  selectedTournament.value = tournament;
  showDetailsModal.value = true;
};

const goToRegistration = (tournamentId) => {
  router.push(`/register/team/${tournamentId}`);
};

const joinDiscord = () => {
  window.open('https://discord.gg/pqZtBtfE6m', '_blank');
};

onMounted(loadTournaments);
</script>

<template>
  <section class="bg-surface-0 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
    <h2 class="text-surface-900 dark:text-surface-0 font-bold text-[2.5rem] leading-[3.75rem] text-center">
      Active Tournaments
    </h2>
    <p class="max-w-[48rem] mb-[2.29rem] mx-auto mt-4 text-surface-600 dark:text-surface-300 text-xl leading-6 text-center">
      Join our competitive gaming tournaments and prove your skills
    </p>

    <div
        class="mt-16 grid w-full md:w-fit mx-auto grid-cols-1 sm:w-[80%] md:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-8 md:gap-y-10">
      <div v-for="tournament in tournaments"
           :key="tournament.id"
           class="group cursor-pointer overflow-hidden relative w-full md:w-[19.35714rem] h-[25rem]">
        <!-- Tournament Card Image -->
        <img class="object-cover rounded-2xl"
             :src="`/images/${tournament.game}.png`"
             :alt="tournament.name"/>

        <!-- Action Buttons -->
        <div
            class="absolute z-20 p-2 right-4 md:-right-16 opacity-100 md:opacity-0 group-hover:opacity-100 group-hover:right-4 transition-all duration-300 top-4 flex flex-col gap-4 rounded-lg backdrop-blur-[5px] [background:linear-gradient(108deg,rgba(0,0,0,0.40)_0%,rgba(0,0,0,0.00)_100%),rgba(255,255,255,0.40)] shadow-[0px_0px_50px_0px_rgba(0,0,0,0.05)_inset,0px_0px_30px_0px_rgba(255,255,255,0.20)]">
          <a @click="goToRegistration(tournament.id)"
             class="w-8 h-8 flex items-center justify-center">
            <i class="pi pi-user-plus text-xl text-white leading-normal"/>
          </a>
          <a @click="showDetails(tournament)"
             class="w-8 h-8 flex items-center justify-center">
            <i class="pi pi-info-circle text-xl text-white leading-normal"/>
          </a>
          <a href="https://discord.gg/pqZtBtfE6m"
             target="_blank"
             class="w-8 h-8 flex items-center justify-center">
            <i class="pi pi-discord text-xl text-white leading-normal"/>
          </a>
        </div>

        <!-- Overlay -->
        <div @click="showDetails(tournament)"
             class="absolute opacity-100 group-hover:opacity-100 transition-opacity duration-300 inset-0 z-10 rounded-2xl bg-[rgba(0,0,0,0.16)]"/>

        <!-- Tournament Info -->
        <div class="absolute z-20 bottom-4 left-4 right-4">
          <h4 class="text-surface-0 dark:text-surface-0 mt-4 text-xl font-semibold">
            {{ tournament.name }}
          </h4>
          <div class="flex flex-col text-surface-100 dark:text-surface-300 mt-1">
            <span>💵 Entry: {{ tournament.meta.entry }}</span>
            <span>🏆 Prize: {{ tournament.meta.prize }}</span>
            <span>📅 {{ tournament.meta.starts }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Tournament Details Modal -->
    <Dialog v-model:visible="showDetailsModal"
            modal
            header="Tournament Details"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '75vw', '641px': '90vw' }">
      <div v-if="selectedTournament" class="p-4">
        <h3 class="text-2xl font-bold mb-4">{{ selectedTournament.name }}</h3>

        <div class="grid grid-cols-2 gap-4">
          <div class="col-span-2 md:col-span-1">
            <h4 class="font-semibold mb-2">Tournament Info</h4>
            <ul class="space-y-2">
              <li>📅 Start: {{ selectedTournament.meta.starts }}</li>
              <li>💵 Entry Fee: {{ selectedTournament.meta.entry }}</li>
              <li>🏆 Prize Pool: {{ selectedTournament.meta.prize }}</li>
              <li>👥 Min Teams: {{ selectedTournament.meta.min_teams }}</li>
              <li>🌎 Region: {{ selectedTournament.meta.regions.toUpperCase() }}</li>
              <li>🏅 Format: {{
                  selectedTournament.meta.stage === 'se' ? 'Single Elimination' : 'Double Elimination'
                }}
              </li>
            </ul>
          </div>

          <div class="col-span-2 md:col-span-1">
            <h4 class="font-semibold mb-2">Game Settings</h4>
            <ul class="space-y-2">
              <li>🎮 Mode: {{ selectedTournament.meta.team_size }}</li>
              <li>🗺️ Maps: Random</li>
              <li>⚔️ Format: Search & Destroy</li>
            </ul>
          </div>
        </div>

        <div class="flex justify-end gap-4 mt-8">
          <Button label="Join Discord"
                  icon="pi pi-discord"
                  @click="joinDiscord"/>
          <Button label="Register Now"
                  icon="pi pi-user-plus"
                  severity="success"
                  @click="goToRegistration(selectedTournament.id)"/>
        </div>
      </div>
    </Dialog>
  </section>
</template>

<style scoped>
/* Add any additional custom styles here */
.tournament-card {
  transition: transform 0.3s ease;
}

.tournament-card:hover {
  transform: translateY(-5px);
}
</style>