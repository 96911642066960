import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

export default definePreset(Aura, {
    semantic: {
        primary: {
            50: '{cyan.50}',
            100: '{cyan.100}',
            200: '{cyan.200}',
            300: '{cyan.300}',
            400: '{cyan.400}',
            500: '{cyan.500}',
            600: '{cyan.600}',
            700: '{cyan.700}',
            800: '{cyan.800}',
            900: '{cyan.900}',
            950: '{cyan.950}'
        }
    },
    components: {
        stepper: {
            stepNumberSize: '5rem',
            stepNumberFontSize: '1.3rem',
            stepTitleFontWeight: 'bold',
            separatorBackground: '{surface.300}',
        }
    }
});
