<script setup>
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import {ref, onMounted} from 'vue';
import axios from '../Axios.js';

const members = ref([]);
const matches = ref(0);

const getMembers = async () => {
  const resp = await axios.get(`/members`);
  members.value = resp.data.filter((user) => !user.bot);
}

const getMatches = async () => {
  const resp = await axios.get(`/matches`);
  matches.value = resp.data.total;
  console.log(resp);
}

onMounted(async () => {
  await getMembers();
  await getMatches();
});
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 pb-10 md:px-12 lg:px-20">
        <div class="font-bold text-surface-900 dark:text-surface-0 text-4xl mb-4">Level Up Your Call of Duty Tournament Experience</div>
        <div class="text-surface-700 dark:text-surface-100 mb-8 leading-normal">Join competitive tournaments with automated matchmaking, easy registration, and seamless gameplay coordination - all through Discord.</div>
        <div class="p-4 rounded-border bg-surface-50 dark:bg-surface-900">
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 p-4">
                    <div class="text-lg text-surface-900 dark:text-surface-0 mb-4 font-medium">Tournament Features</div>
                    <Accordion>
                        <AccordionPanel value="0">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-users text-sm" />
                                    </span>
                                    <span>Easy Team Registration</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Quick and simple registration process with just a few clicks. Join as a full team or find teammates through our random team matchmaking. Substitute player system available if your teammate can't make it.</p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="1">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-clock text-sm" />
                                    </span>
                                    <span>Regular Tournaments</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Join daily and weekly tournaments for both 2v2 and 3v3 Search and Destroy matches. Clear schedule announcements and automatic reminders ensure you never miss a match.</p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="2">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-comments text-sm" />
                                    </span>
                                    <span>Private Match Channels</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Get automatic private Discord channels for each match. Coordinate with your opponents, share game details, and report results all in one organized space.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="3">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-chart-line text-sm" />
                                    </span>
                                    <span>Live Tournament Progress</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Track your tournament journey with real-time bracket updates and match notifications. Always know who you're playing next and when your matches are scheduled.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="4">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-check-circle text-sm" />
                                    </span>
                                    <span>Quick Match Setup</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Simple "Ready Up" system gets your matches started quickly. No more waiting around - once both teams are ready, you'll be notified immediately to begin your match.</p>
                            </AccordionContent>
                        </AccordionPanel>
                    </Accordion>
                </div>
                <div class="w-full lg:w-6/12 p-4">
                    <div class="text-lg text-surface-900 dark:text-surface-0 mb-4 font-medium">Player Support</div>
                    <Accordion>
                        <AccordionPanel value="0">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-credit-card text-sm" />
                                    </span>
                                    <span>Easy Entry Payment</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Secure and simple tournament entry fee payment through Stripe. Buy credits once and use them for multiple tournaments, with clear transaction history and automatic confirmation.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="1">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-shield text-sm" />
                                    </span>
                                    <span>Fair Play Protection</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Submit match evidence and report issues easily through our dispute system. Quick response times ensure your competitive experience stays fair and enjoyable.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="2">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-user-plus text-sm" />
                                    </span>
                                    <span>Find Teammates</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Don't have a full team? No problem! Join our random team tournaments to get matched with other players, or use our substitute system when your regular teammates aren't available.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="3">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-bell text-sm" />
                                    </span>
                                    <span>Match Notifications</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Never miss a match with our automatic notification system. Get alerts for tournament start times, match readiness, and opponent updates directly through Discord.</p>
                            </AccordionContent>
                        </AccordionPanel>

                        <AccordionPanel value="4">
                            <AccordionHeader>
                                <div class="flex items-center">
                                    <span class="inline-flex items-center justify-center rounded-full bg-surface-300 dark:bg-surface-500 mr-2 text-surface-700 dark:text-surface-100" style="width: 2rem; height: 2rem">
                                        <i class="pi pi-trophy text-sm" />
                                    </span>
                                    <span>Prize Tracking</span>
                                </div>
                            </AccordionHeader>
                            <AccordionContent>
                                <p class="leading-normal">Keep track of your tournament winnings and achievements. Clear prize pool information for each tournament and automated winner announcements in the community.</p>
                            </AccordionContent>
                        </AccordionPanel>
                    </Accordion>
                </div>
            </div>
        </div>
    </div>

  <div class="bg-surface-0 dark:bg-surface-950 px-6 md:px-12 lg:px-20">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-6 lg:col-span-3 p-4">
        <div class="bg-blue-50 dark:bg-blue-400/30 text-center p-8 rounded-border flex flex-col">
          <i class="text-blue-500 dark:text-blue-200 pi pi-users !text-4xl block mb-6"/>
          <span class="text-surface-900 dark:text-surface-0 text-5xl">{{ members.length }}+</span>
          <div class="text-blue-700 dark:text-blue-300 mt-4 font-medium">Total Members</div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3 p-4">
        <div class="bg-purple-50 dark:bg-purple-400/30 text-center p-8 rounded-border flex flex-col">
          <i class="text-purple-500 dark:text-purple-200 pi pi-trophy !text-4xl block mb-6"/>
          <span class="text-surface-900 dark:text-surface-0 text-5xl">5+</span>
          <div class="text-purple-700 dark:text-purple-300 mt-4 font-medium">Daily Tournaments</div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3 p-4">
        <div class="bg-teal-50 dark:bg-teal-400/30 text-center p-8 rounded-border flex flex-col">
          <i class="text-teal-500 dark:text-teal-200 pi pi-money-bill !text-4xl block mb-6"/>
          <span class="text-surface-900 dark:text-surface-0 text-5xl">$5k+</span>
          <div class="text-teal-700 dark:text-teal-300 mt-4 font-medium">Paid To Participants</div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3 p-4">
        <div class="bg-indigo-50 dark:bg-indigo-400/30 text-center p-8 rounded-border flex flex-col">
          <i class="text-indigo-500 dark:text-indigo-200 pi pi-clone !text-4xl block mb-6"/>
          <span class="text-surface-900 dark:text-surface-0 text-5xl">{{matches}}</span>
          <div class="text-indigo-700 dark:text-indigo-300 mt-4 font-medium">Matches Completed</div>
        </div>
      </div>
    </div>
  </div>
</template>